<template lang="pug">
layout(:title="$route.name" :rotas="rotas")
  Desktop(
    :local="local",
    :payment_sys_config="payment_sys_config" 
    :payment_menurocket_config="payment_menurocket_config", 
    :receipt="receipt"
  )
  //- Desktop(:local="local",:payment_sys_config="payment_sys_config", :receipt="receipt",v-if="$vuetify.breakpoint.smAndUp")
  //- Mobile(:local="local" v-else)
</template>

<script>
import Desktop from "../components/Desktop/Index.vue";
// import Mobile from "../components/Mobile/Index.vue";
import { mapActions } from "vuex";
import { EventBus } from "@/main";

export default {
  components: {
    Desktop
    // Mobile
  },
  data() {
    return {
      local: {
        list_tables: []
      },
      payment_sys_config: {},
      payment_menurocket_config: {},
      receipt: {},
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "Configurações",
          path: "/configurar-loja"
        }
      ]
    };
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    saveLocal(data) {
      setTimeout(() => {
        this.$store.dispatch("enableLoading");
        this.$run("marketplaces/set-config-local", {
          local: data
        })
          .then(() => {
            this.$store.dispatch("disableLoading");
            this.createGlobalMessage({
              timeout: 6000,
              type: "sucess",
              message: "Local salvo com sucesso!"
            });
          })
          .catch(e => {});
      }, 300);
    },
    savePaymentSysConfig(data) {
      setTimeout(() => {
        this.$store.dispatch("enableLoading");
        this.$run("marketplaces/set-config-payment-sys", {
          payment_sys_config: data
        })
          .then(() => {
            this.$store.dispatch("disableLoading");
            this.createGlobalMessage({
              timeout: 1000,
              type: "success",
              icon: "mdi-check-circle",
              message: "Formas de pagamento atualizadas 🚀"
            });
          })
          .catch(e => {});
      }, 300);
    },
    saveReceiptConfig(data) {
      setTimeout(() => {
        this.$store.dispatch("enableLoading");
        this.$run("marketplaces/set-config-receipt", {
          receipt: data
        })
          .then(() => {
            this.$store.dispatch("disableLoading");
            this.createGlobalMessage({
              timeout: 6000,
              type: "success",
              message: "Os textos foram salvos 🚀"
            });
          })
          .catch(e => {});
      }, 300);
    },
    getLocal() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-local")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.local = res.data.local;
        })
        .catch(e => {});
    },
    getConfigPaymentSys() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-payment-sys")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.payment_sys_config = res.data.payment_sys_config;
        })
        .catch(e => {});
    },
    getConfigPaymentMenurocket() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-payment-menurocket")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.payment_menurocket_config = res.data.payment_menurocket_config;
        })
        .catch(e => {});
    },
    getConfigReceipt() {
      this.$store.dispatch("enableLoading");
      this.$run("marketplaces/get-config-receipt")
        .then(res => {
          this.$store.dispatch("disableLoading");
          this.receipt = res.data.receipt;
        })
        .catch(e => {});
    }
  },
  created() {
    this.getLocal();
    this.getConfigPaymentSys();
    this.getConfigPaymentMenurocket();
    this.getConfigReceipt();
    EventBus.$on("saveLocal", data => this.saveLocal(data));
    EventBus.$on("savePaymentSysConfig", data =>
      this.savePaymentSysConfig(data)
    );
    EventBus.$on("saveReceiptConfig", data => this.saveReceiptConfig(data));
  }
};
</script>
