<template lang="pug">
div.vitrify-content.expande-horizontal.wrap(:class="{ 'pt-3': $vuetify.breakpoint.smAndUp }" :style="`max-height: 95vh; overflow: ${$vuetify.breakpoint.smAndDown ? 'auto' : 'hidden'};`")
  v-flex(xs12 md4)
    storeConfig
  v-flex(xs12 md4).mb-3
    taxConfig(:payment_sys_config="payment_sys_config" :payment_menurocket_config="payment_menurocket_config")
  v-flex(xs12 md4)
    receiptConfig(:view="showView", :receipt="receipt")
  //- deliveryConfig(:view="showView")
</template>

<script>
import localConfig from "../Desktop/Local/Index.vue";
import tableConfig from "../Desktop/Table/Index.vue";
import receiptConfig from "../Desktop/Receipt/Index.vue";
import taxConfig from "../Desktop/Tax/Index.vue";
import deliveryConfig from "../Desktop/Delivery/Index.vue";
import storeConfig from "../Desktop/Store/Index.vue";
import { EventBus } from "@/main.js";
export default {
  props: [
    "local",
    "payment_sys_config",
    "payment_menurocket_config",
    "receipt"
  ],
  data() {
    return {
      showView: ""
    };
  },
  components: {
    localConfig,
    tableConfig,
    receiptConfig,
    taxConfig,
    deliveryConfig,
    storeConfig
  },
  methods: {},
  created() {
    const self = this;
    EventBus.$on("set-config-view", view => {
      self.showView = view;
      self.$forceUpdate();
    });
  }
};
</script>
