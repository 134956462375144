<template lang="pug">
v-flex(xs12 :class="{ 'px-2 mb-2': $vuetify.breakpoint.smAndUp }").fonte
    v-card(style="border-radius: 6px;" color="#f2f2f2").pb-6.expande-horizontal.wrap
        div.expande-horizontal.wrap
            v-list(dense nav color="transparent")
              v-list-item
                v-avatar.mr-2(color="#363636" size="31")
                  v-icon(:color="$theme.primary") mdi-cash-sync
                v-list-item-content 
                  v-list-item-title
                    span.fonte Formas de pagamento
                  span.fonte.fonteMini.grey--text Ative as formas de pagamento que você usa
                v-list-item-action 
                  v-btn(small).fonte.white--text.elevation-0(rounded @click="savePaymentSysConfig",:color="$theme.third") Salvar
            v-flex(xs12)
                v-list(color="#f2f2f2" two-line,style="width: 100%;")
                    v-list-item
                        v-avatar(size="31",:color="$theme.primary").mr-2
                            v-icon(size="21",color="#363636") mdi-cash
                        v-list-item-content 
                            v-list-item-title
                                span.fonte Dinheiro
                        v-list-item-action.pt-3
                            v-switch(@change="save",v-model="payment_sys_config['money'].active",:color="$theme.third")
                    v-divider 
                    v-list-item
                        v-avatar(size="31",:color="$theme.primary").mr-2
                            v-icon(size="21",color="#363636") mdi-credit-card-outline
                        v-list-item-content 
                            v-list-item-title
                                span.fonte Cartão de Crédito
                        v-list-item-action.pt-3
                            v-switch(@change="save",v-model="payment_sys_config['card'].active",:color="$theme.third")
                    v-divider 
                    v-list-item
                        v-avatar(size="31",:color="$theme.primary").mr-2
                            v-icon(size="21",color="#363636") mdi-credit-card-chip-outline
                        v-list-item-content 
                            v-list-item-title
                                span.fonte Cartão de Débito
                        v-list-item-action.pt-3
                            v-switch(@change="save",v-model="payment_sys_config['debit'].active",:color="$theme.third")
                    v-divider 
                    v-list-item
                        v-avatar(size="31",:color="$theme.primary").mr-2
                            img(style="width: 21px;",src="img/config/pix.svg",color="#363636")
                        v-list-item-content 
                            v-list-item-title
                                span.fonte Pix
                        v-list-item-action.pt-3
                            v-switch(@change="save",v-model="payment_sys_config['pix'].active",:color="$theme.third")
                    v-divider 
                    v-list-item
                        v-avatar(size="31",:color="$theme.primary").mr-2
                            v-icon(size="21",color="#363636") mdi-account-hard-hat
                        v-list-item-content 
                            v-list-item-title
                                span.fonte Acabamento
                                v-flex(xs12)
                                    v-text-field(
                                        filled
                                        dense
                                        suffix="%"
                                        hint="Será aplicado sobre a venda"
                                        persistent-hint                                        
                                        type="Number"
                                        placeholder="ex: 2.89"
                                        v-model="payment_sys_config['finishing'].tax_porcentage"
                                    )
</template>

<script>
import { EventBus } from "@/main";
export default {
  props: ["payment_sys_config"],
  data() {
    return {
      tax_tab: 0
    };
  },
  methods: {
    changeConfigView(view) {
      EventBus.$emit("set-config-view", view);
    },
    savePaymentSysConfig() {
      EventBus.$emit("savePaymentSysConfig", this.payment_sys_config);
    }
  }
};
</script>

<style>
.config-local-avatar {
  width: 150px;
  height: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
}
</style>
