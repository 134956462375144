<template lang="pug">
v-flex(xs12 :class="{ 'px-2 mb-6': $vuetify.breakpoint.smAndUp }").fonte
    v-card(style="border-radius: 6px;" color="#f2f2f2").pb-3.expande-horizontal.wrap
        div.expande-horizontal.centraliza.column.px-3
            v-list(dense nav color="transparent")
              v-list-item
                v-avatar.mr-2(color="#363636" size="31")
                  v-icon(:color="$theme.primary") mdi-printer-pos-edit
                v-list-item-content 
                  v-list-item-title
                    span.fonte Textos do orçamento
                  span.fonte.fonteMini.grey--text Personalize os textos do orçamento e a validade da proposta.
                v-list-item-action 
                  v-btn(small).fonte.white--text.elevation-0(rounded @click="saveReceipt",:color="$theme.third") Salvar
            div.expande-horizontal.centraliza.mt-3
                v-flex(xs12 md12)
                    v-flex(xs12).ma-3
                        v-textarea(
                            height="100"
                            filled
                            dense
                            v-model="receipt.show_header_text.text"
                            label="Texto de Cabeçalho"
                        )
                    v-flex(xs12).ma-3
                        v-textarea(
                            height="100"
                            filled
                            dense
                            v-model="receipt.show_footer_text.text"
                            label="Texto de Rodapé"
                        )
                    v-flex(xs12).ma-3
                      v-text-field(
                          filled
                          dense
                          v-model="receipt.top_right_text"
                          label="Canto superior direito"
                      )
                    v-flex(xs12).ma-3
                      v-text-field(
                          filled
                          dense
                          v-model="receipt.bottom_right_text"
                          label="Canto inferior direito"
                      )
                    v-flex(xs12).ma-3
                      v-text-field(
                          filled
                          dense
                          placeholder="Ex: Não fazemos instalação"
                          v-model="receipt.short_obs"
                          label="Observação curta"
                      )
                    v-flex(xs12).ma-3
                      v-text-field(
                          filled
                          dense
                          type="number"
                          placeholder="O padrão é 7 dias"
                          v-model="receipt.validate_proposal_in_days"
                          label="Validade da proposta em dias"
                      )
                    //- v-flex(xs12).ml-3
                    //-     v-btn.fonte.white--text.elevation-0(rounded @click="saveReceipt",:color="$theme.third") Salvar
                        
</template>

<script>
import { EventBus } from "@/main";
export default {
  props: {
    receipt: {
      type: Object,
      default: () => {
        return {
          show_my_address: { active: true },
          show_my_logo: { active: true },
          show_my_qrcode: { active: true },
          show_client_details: { active: true },
          show_header_text: { active: true, text: "" },
          show_footer_text: { active: true, text: "" }
        };
      }
    }
  },
  methods: {
    changeConfigView(view) {
      EventBus.$emit("set-config-view", view);
    },
    saveReceipt() {
      EventBus.$emit("saveReceiptConfig", this.receipt);
    }
  }
};
</script>

<style>
.config-receipt-avatar {
  width: 150px;
  height: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
}
</style>
